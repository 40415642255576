
import { createSelector } from 'reselect';

// import type { Track } from '../reducers/tracks';

export const currentTrackSelector = createSelector(
  state => state.tracks.entities,
  state => state.player.currentTrackId,
  state => state.storytellers.entities,
  (tracks, currentTrackId, storytellers) => {
    let track = null;
    let id = String(currentTrackId);

    if (tracks.has(id) && storytellers.count() > 0) {
      track = (tracks.get(id));
    }

    return {
      track,
    };
  },
);

export const tracksSelector = createSelector(
  state => state.player.tracks,
  state => state.tracks.entities,
  state => state.storytellers.entities,
  (trackList, trackEntities, storytellerEntities) => {
    const tracks = trackList.map(trackId => {
      const track = trackEntities.get(String(trackId));
      let storyteller = storytellerEntities.get(String(track.storyteller));

      if (typeof storyteller == 'undefined') {
        console.log('Storyteller not set for Track '+ trackId);
        storyteller = false;
      }

      let date = false;
      if (!track.date) {
        console.log('Track date not set for Track '+trackId);
      } else {
        date = track.date.split('T')[0];
      }

      return {
        ...track,
        date,
        storyteller,
      };
    });

    return {
      tracks,
    };
  },
);

export const sponsorTracksSelector = createSelector(
  tracksSelector,
  state => state.player.get('sponsors'),
  state => state.filters.get('term'),
  state => state.filters.get('storytellerId'),
  ({ tracks }, sponsors, term, storytellerId) => {
    return {
      tracks: sponsors.map(id => {
        return tracks.find(t => t.id === id);
      }).toList().filter(t => {
        if (t && storytellerId) {
          return t.storyteller.id === storytellerId;
        }
        if (t && term && term.trim().length > 0) {
          return !!~t.title.toLowerCase().indexOf(term.toLowerCase());
        }
        return true;
      }),
    };
  },
);

export const favoriteTracksSelector = createSelector(
  tracksSelector,
  state => state.player.get('sponsors'),
  state => state.player.get('favorites'),
  state => state.filters.get('term'),
  state => state.filters.get('storytellerId'),
  ({ tracks }, sponsors, favorites, term, storytellerId) => {
    return {
      tracks: favorites
        .subtract(sponsors)
        .map(id => tracks.find(t => t.id == id))
        .toList()
        .filter(t => {
          if (t && storytellerId) {
            return t.storyteller.id == storytellerId;
          }
          if (t && term && term.trim().length > 0) {
            return !!~t.title.toLowerCase().indexOf(term.toLowerCase());
          }
          return true;
        }),
    };
  },
);

export const nonFavoriteTracksSelector = createSelector(
  tracksSelector,
  state => state.player.get('tracks'),
  state => state.player.get('favorites'),
  state => state.player.get('sponsors'),
  (entities, tracks, favorites, sponsors) => {
    const nonFavoriteTracks = tracks
      .subtract(favorites)
      .subtract(sponsors)
      .map(id => entities.tracks.find(t => t.id === id))
      .toList();

    return {
      tracks: nonFavoriteTracks,
    };
  }
);

export const combineTracksSelector = createSelector(
  sponsorTracksSelector,
  favoriteTracksSelector,
  nonFavoriteTracksSelector,
  (sponsors, favorites, nonFavorites) => ({
    tracks: sponsors.tracks
      .concat(favorites.tracks.concat(nonFavorites)),
  }),
);

export const storytellerSelector = createSelector(
  currentTrackSelector,
  state => state.storytellers.entities,
  state => state.player.get('sponsors'),
  state => state.sponsors,
  ({ track }, storytellerEntities, sponsorTrackIds, sponsor) => {
    const storyteller = (storytellerEntities
        && track.storyteller
      && storytellerEntities.get(String(track.storyteller)))
      || {};

    const sponsors = sponsorTrackIds.toList()
          .filter(str => str === track.id)
          .count()
      ? sponsor
      : {};

    return {
      storyteller,
      sponsors,
    };
  },
);

export const progressSelector = createSelector(
  currentTrackSelector,
  state => state.player.currentTime,
  ({ track }, time) => {
    const { duration } = track.audio;
    const progress = 100 * (time / duration);

    return {
      progress,
    };
  },
);

export const storytellersSelector = createSelector(
  state => state.storytellers,
  state => state.filters,
  (storytellers, filters) => {
    return {
      storytellers: storytellers.get('entities')
        .sortBy(
          storyteller => storyteller.title,
          (a, b) => a < b ? -1 : 1
        )
        .valueSeq(),
      selected: filters.get('storytellerId'),
    };
  },
);

export const getSearchTerm = createSelector(
  state => state.filters.get('term'),
  term => {
    return { term };
  },
);

export const locationListSelector = createSelector(
  state => state.locations.get('entities'),
  state => state.filters,
  (locations, filters) => {
    return {
      locations: locations.toList(),
      selected: filters.get('locationId'),
    };
  }
);

import React from 'react';
import ReactDOM from 'react-dom/client';
// vitals for debugging
import reportWebVitals from './reportWebVitals';
// redux
import { Provider } from 'react-redux';
import { configureStore } from './store';
import App from './components/App'

import 'font-awesome/css/font-awesome.css';
// import 'react-select/dist/react-select.css';

const store = configureStore({});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { makeApiRequest, Schemas } from '../middleware/api';
import {
  setFavorite,
  setCurrentTrackId,
} from '../actions/trackSelectionActions';
import { play } from '../actions/playActions';
import { KEY } from './storage';

export function parseHash() {
  try {
    const { hash } = window.location;
    const matches: any[] = hash.match(/([A-Z0-9_-]+)=([A-Z0-9_-]+)/gi) || [];
    let sponsorsHashToQueryParam = (hash.match(/#\/(sponsors)\/(.+)/) || [])
      .slice(1)
      .join('=') || 'sponsors=';

    // Live preview
    if (window.top !== window.self && document.referrer) {
      const iframeSponsorsSlug = document.referrer.match(/\/(\d*-)(.+)/) || [];
      if (iframeSponsorsSlug[2]) {
        sponsorsHashToQueryParam = `sponsors=${iframeSponsorsSlug[2]}`;
      }
    }

    matches.push(sponsorsHashToQueryParam);

    return matches.reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {});
  } catch (ex) {
    return {};
  }
}

export const GET_TRACK_SUCCESS = 'GET_TRACK_SUCCESS';

function onHashChange({ dispatch, getState }) {
  return async () => {
    const { actionType, storyId, sponsors } = parseHash();

    if ((!sponsors && getState().player.get('sponsors').count())
        || (sponsors && !getState().player.get('sponsors').count())
        || (sponsors && getState().sponsors.get('slug')
          && sponsors !== getState().sponsors.get('slug'))) {
      return window.location.reload();
    }

    const tracks = getState().player.get('tracks').toArray();

    if (storyId && tracks.indexOf(storyId) < 0) {
      const response = await makeApiRequest(`tracks/single`, Schemas.TRACK, {
        id: storyId,
      });

      dispatch({
        type: GET_TRACK_SUCCESS,
        ...response.data,
      });
    }

    switch (actionType) {
    case 'ADD_TO_FAVORITES':
      return dispatch(setFavorite(storyId));
    case 'ADD_AND_PLAY':
    default:
      // @todo: get store from configureStore
      break;
      // composedStore.dispatch(setCurrentTrackId(storyId));
      // dispatch(setFavorite(storyId));
      // return composedStore.dispatch(play());
    }
  };
}

export default function hashEnhancer(): Function {
  let localData;
  const query = parseHash();

  if (query.storyId) {
    try {
      const json = localStorage.getItem(KEY);
      if (json) {
        localData = JSON.parse(json);
      } else {
        throw new Error('No localStorage');
      }
    } catch (ex) {
      localData = { favorites: [] };
    }

    if (localData.favorites.indexOf(query.storyId) === -1) {
      localData.favorites.unshift(query.storyId);
      localStorage.setItem(KEY, JSON.stringify(localData));
    }
  }

  return next => (reducer, initialState) => {
    const store = next(reducer, initialState);

    const middlewareAPI = {
      dispatch: store.dispatch,
      getState: store.getState,
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('hashchange', onHashChange(middlewareAPI), false);
    }

    return store;
  };
}
